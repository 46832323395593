import anime from 'animejs'

// to reset values the brainless way do this after close animations
const clearAnimation = (items) => {
  items.forEach((item) => {
    if (item.hasAttribute('style')) {
      item.removeAttribute('style')
    }
  })
}

const openOverlay = (overlay) => {
  anime({
    targets: overlay,
    opacity: [0, 1],
    duration: 300,
    easing: 'cubicBezier(.5, .05, .1, .3)',
    begin: () => {
      overlay.style.display = 'block'
    },
  })
}
const closeOverlay = (overlay) => {
  anime({
    targets: overlay,
    opacity: [1, 0],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
    complete: () => {
      overlay.style.display = 'none'
    },
  })
}

const openSearch = (self, begin, complete) => {
  anime({
    targets: self.searchPanel.querySelector('.search-panel__inner'),
    translateY: ['-100%', 0],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
    begin: () => {
      // set it in correct position to do overlay
      self.searchPanel.style.left = 0
      self.searchCloseButton.style.opacity = 0
      begin()
    },
    complete: () => {
      complete()
    },
  })

  anime({
    targets: self.searchCloseButton,
    opacity: [0, 1],
    duration: 300,
    easing: 'cubicBezier(.5, .05, .1, .3)',
  })
  openOverlay(self.overlay)
}
const closeSearch = (self, begin, complete) => {
  anime({
    targets: self.searchPanel.querySelector('.search-panel__inner'),
    translateY: [0, '-100%'],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
    begin: () => {
      begin()
    },
    complete: () => {
      const inner = self.searchPanel.querySelector('.search-panel__inner')
      clearAnimation([self.searchPanel, self.searchCloseButton, inner])
      complete()
    },
  })
  anime({
    targets: self.searchCloseButton,
    opacity: [1, 0],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
  })

  closeOverlay(self.overlay)
}
const animateOpenGroup = (group, self, begin, complete) => {
  const content = group.querySelector('.nav__group__sections')

  let tl = anime.timeline({
    easing: 'cubicBezier(.5, .05, .1, .3)',
    begin: () => {
      group.style.left = '0'
      group.style.left = '0'
      content.style.opacity = 0
      begin()
    },
    complete: () => {
      complete()
    },
  })
  tl.add({
    targets: group,
    opacity: [0, 1],
    translateY: ['-80%', 0],
    duration: 400,
  })

  tl.add(
    {
      targets: content,
      opacity: [0, 1],
      translateY: ['-10px', 0],
      duration: 300,
    },
    300
  )

  openOverlay(self.overlay)
}
const animateCloseGroup = (group, self, begin, complete) => {
  let tl = anime.timeline({
    easing: 'cubicBezier(.5, .05, .1, .3)',
    complete: () => {
      anime({
        targets: group,
        translateY: ['0', '-100%'],
        duration: 400,
        easing: 'cubicBezier(.5, .05, .1, .3)',
        begin: () => {
          begin()
        },
        complete: () => {
          group.removeAttribute('style')
          const animatedSections = group.querySelectorAll(
            '.nav__group__section'
          )
          clearAnimation(animatedSections)
          group.style.visibility = 'hidden'

          complete()
        },
      })
    },
  })
  tl.add({
    targets: group.querySelectorAll('.nav__group__section'),
    opacity: [1, 0],
    translateY: [0, '-20px'],
    duration: 100,
  })
  closeOverlay(self.overlay)
}

export { openSearch, closeSearch, animateOpenGroup, animateCloseGroup }
