import anime from 'animejs'
// to reset values the brainless way do this after close animations
const clearAnimation = (items) => {
  items.forEach((item) => {
    if (item.hasAttribute('style')) {
      item.removeAttribute('style')
    }
  })
}
const openNav = (self, begin, complete) => {
  const links = self.$node.querySelectorAll(
    '.nav__group-button, .nav__secondary-links li, .nav__link'
  )

  if (self.isTablet) {
    // tablet animation
    anime({
      targets: self.navPanel,
      duration: 300,
      right: ['-100%', 0],
      opacity: [0, 1],
      tranformX: 0,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        self.navPanel.style.visibility = 'visible'
        begin()
      },
      complete: () => {
        complete()
      },
    })

    anime({
      targets: self.tabletOverlay,
      opacity: [0, 1],
      duration: 200,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        const overlay = self.tabletOverlay
        overlay.style.display = 'block'
      },
    })
  } else {
    // mobile animation
    anime({
      targets: self.navPanel,
      duration: 300,
      opacity: [0, 1],
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        self.navPanel.style.left = 0
        links.forEach((elem) => {
          elem.style.opacity = 0
        })
        begin()
      },
      complete: () => {
        anime({
          delay: anime.stagger(100),
          targets: links,
          duration: 300,
          opacity: [0, 1],
          translateX: ['-20px', '0px'],
          easing: 'cubicBezier(.5, .05, .1, .3)',
        })
        complete()
      },
    })
  }
}

const closeNav = (self, begin, complete) => {
  let actives = [self.navPanel]
  if (self.currentPanel) {
    actives.push(self.currentPanel)
  }

  if (self.isTablet) {
    let tl = anime.timeline({
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        const overlay = self.tabletOverlay
        overlay.style.display = 'none'
        actives.forEach((active) => {
          active.removeAttribute('style')
        })
        complete()
      },
    })

    tl.add({
      targets: actives,
      duration: 400,
      right: [0, '-100%'],
    })

    tl.add({
      targets: self.tabletOverlay,
      opacity: [1, 0],
      duration: 300,
    })
  } else {
    anime({
      targets: actives,
      duration: 300,
      opacity: [1, 0],
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        actives.forEach((active) => {
          active.removeAttribute('style')
        })
        complete()
      },
    })
  }
}

const animateGroup = (self, begin, complete) => {
  if (self.isTablet) {
    anime({
      targets: self.currentPanel,
      duration: 300,
      right: ['-100%', 0],
      tranformX: 0,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        self.currentPanel.style.visibility = 'visible'
        self.currentPanel.style.left = 'auto'
        begin()
      },
      complete: () => {
        complete()
      },
    })
  } else {
    anime({
      targets: self.currentPanel,
      opacity: [0, 1],
      duration: 400,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        // a11y show
        self.currentPanel.style.visibility = 'visible'
        self.currentPanel.style.left = 0
        begin()
      },
      complete: () => {
        complete()
      },
    })
  }
}

const animateBack = (self, beign, complete) => {
  if (self.isTablet) {
    anime({
      targets: self.currentPanel,
      opacity: [1, 0],
      duration: 400,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        self.currentPanel.removeAttribute('style')
        // a11y show
        self.currentPanel.style.visibility = 'hidden'
        setTimeout(() => {
          self.currentPanel = null
        })
      },
    })
  } else {
    anime({
      targets: self.currentPanel,
      opacity: [1, 0],
      duration: 400,
      easing: 'cubicBezier(.5, .05, .1, .3)',
    })
    anime({
      targets: self.currentPanel.querySelector('.nav__group__sections'),
      translateX: [0, '-30px'],
      duration: 400,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      complete: () => {
        self.currentPanel.removeAttribute('style')
        self.currentPanel
          .querySelector('.nav__group__sections')
          .removeAttribute('style')
        // a11y show
        self.currentPanel.style.visibility = 'hidden'
        setTimeout(() => {
          self.currentPanel = null
        })
      },
    })
  }
}

const animateSearchOpen = (self, begin, complete) => {
  const panelInner = self.searchPanel.querySelector('.search-panel__inner')
  const input = self.searchPanel.querySelector('.search-input')

  let tl = anime.timeline({
    begin: () => {
      self.searchPanel.style.left = 0
      self.searchPanel.querySelector('.search-panel__header').style.opacity = 0
      input.style.opacity = 0
      begin()
    },
    complete: () => {
      complete()
    },
  })

  tl.add({
    targets: self.searchPanel.querySelector('.search-panel__header'),
    opacity: [0, 1],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
  })

  tl.add(
    {
      targets: panelInner,
      translateY: ['-250px', 0],
      duration: 300,
      easing: 'cubicBezier(.5, .05, .1, .3)',
    },
    200
  )

  tl.add(
    {
      targets: self.tabletOverlay,
      opacity: [0, 1],
      duration: 300,
      easing: 'cubicBezier(.5, .05, .1, .3)',
      begin: () => {
        const overlay = self.tabletOverlay
        overlay.style.display = 'block'
      },
    },
    200
  )

  tl.add(
    {
      targets: input,
      opacity: [0, 1],
      duration: 300,
      easing: 'cubicBezier(.5, .05, .1, .3)',
    },
    400
  )
}

const animateCloseSearch = (self, begin, complete) => {
  let tl = anime.timeline({
    begin: () => {
      begin()
    },
    complete: () => {
      // clean animations
      const header = self.searchPanel.querySelector('.search-panel__header')
      const panelInner = self.searchPanel.querySelector('.search-panel__inner')
      const input = self.searchPanel.querySelector('.search-input')
      clearAnimation([header, panelInner, input, self.searchPanel])
      complete()
    },
  })

  tl.add({
    targets: self.searchPanel,
    opacity: [1, 0],
    duration: 300,
    easing: 'cubicBezier(.5, .05, .1, .3)',
  })
  tl.add({
    targets: self.tabletOverlay,
    opacity: [1, 0],
    duration: 200,
    easing: 'cubicBezier(.5, .05, .1, .3)',
    complete: () => {
      const overlay = self.tabletOverlay
      overlay.style.display = 'none'
    },
  })
}

export {
  openNav,
  closeNav,
  animateGroup,
  animateBack,
  animateSearchOpen,
  animateCloseSearch,
}
