import { createBehavior } from '@area17/a17-behaviors'
import Elipsis from 'ellipsis.js'

const mobileConfig = {
  ellipsis: '…',
  responsive: true,
  lines: 2,
  break_word: true,
}

const desktopConfig = {
  ellipsis: '…',
  responsive: true,
  lines: 1,
  break_word: true,
}

const test = createBehavior(
  'linkPill',
  {},
  {
    init() {
      this.mobile = this.$node.querySelector('.link-pill__title .mobile')
      this.desktop = this.$node.querySelector('.link-pill__title .desktop')
      this.controller = Elipsis(desktopConfig)
      this.controller.add(this.desktop)
      this.controller = Elipsis(mobileConfig)
      this.controller.add(this.mobile)
    },
  }
)

export default test
