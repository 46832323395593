import { escapeString } from '@area17/a17-helpers'
import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || []

const SocialSharing = createBehavior(
  'SocialSharing',
  {
    pageUrl() {
      const tempUrl = this.options.url
      return tempUrl && tempUrl.length > 0 ? tempUrl : window.location.href
    },

    pageUrlEscaped() {
      return escapeString(this.pageUrl())
    },

    pageTitle() {
      return escapeString(document.title)
    },

    buildFacebookURL() {
      return (
        'https://www.facebook.com/sharer/sharer.php?u=' + this.pageUrlEscaped()
      )
    },

    buildLinkedinURL() {
      return (
        'https://www.linkedin.com/shareArticle?mini=true' +
        '&url=' +
        this.pageUrlEscaped() +
        '&summary=' +
        this.pageTitle()
      )
    },

    buildTwitterURL() {
      const meta = document.querySelector('meta[name="twitter:description"]')
      const content = meta && meta.getAttribute('content')
      const tweetText = content ? content : this.pageTitle()

      return (
        'https://twitter.com/intent/tweet?' +
        'url=' +
        this.pageUrlEscaped() +
        '&text=' +
        tweetText +
        '&via=' +
        (this.options.twitterVia || '')
      )
    },

    buildMailtoUrl() {
      const meta = document.querySelector('meta[name="description"]')
      const content = meta && meta.getAttribute('content')

      return (
        'mailto:' +
        '?subject=' + this.pageTitle() +
        '&body=' + this.pageUrlEscaped()
      )
    },

    openWindow(url, options) {
      if (!options) {
        options = {}
      }

      const width = options.width || 575
      const height = options.height || 400
      const left = (window.outerWidth - width) / 2
      const top = (window.outerHeight - height) / 2
      const opts =
        'status=1' +
        ',width=' +
        width +
        ',height=' +
        height +
        ',top=' +
        top +
        ',left=' +
        left
      if (this._data.socialWindowRef && !this._data.socialWindowRef.closed) {
        this._data.socialWindowRef.close()
      }
      setTimeout(() => {
        this._data.socialWindowRef = window.open(url, 'A17', opts)
        this._data.socialWindowRef.opener = null
      }, 250)
    },

    openMailtoLink(url) {
      let a = document.createElement('a');
      a.target="_blank";
      a.href=url;
      
      setTimeout(() => {
        a.click();
      }, 250)
    },

    addListener(arr, func) {
      const arrLength = arr.length
      for (let i = 0; i < arrLength; i++) {
        arr[i].addEventListener('click', func, false)
      }
    },

    removeListener(arr, func) {
      const arrLength = arr.length
      for (let i = 0; i < arrLength; i++) {
        arr[i].removeEventListener('click', func)
      }
    },

    // social networks
    facebook(event) {
      event.stopPropagation()
      const url = this.buildFacebookURL()
      this.openWindow(url)
    },

    twitter(event) {
      event.stopPropagation()
      const url = this.buildTwitterURL()
      this.openWindow(url, { height: 275 })
    },

    linkedin(event) {
      event.stopPropagation()
      const url = this.buildLinkedinURL()
      this.openWindow(url, { height: 500 })
    },

    email(event) {
      event.stopPropagation()
      const url = this.buildMailtoUrl()
      this.openMailtoLink(url)
    },

    copy(event) {
      event.stopPropagation()

      navigator.clipboard.writeText(this.pageUrl()).then(
        () => {
          const $copyIconDefault = this.getChild('copy-icon')
          const $copyIconCheck = this.getChild('copy-checkmark')
          const $copyLabel = this.getChild('copy-label')
          const $copyMessage = this.getChild('copy-message')

          $copyIconDefault.classList.add('hidden')
          $copyIconCheck.classList.remove('hidden')
          $copyMessage.setAttribute('role', 'alert')
          $copyMessage.setAttribute('aria-hidden', 'false')
          $copyMessage.classList.remove('sr-only')
          $copyLabel.setAttribute('aria-hidden', 'true')
          $copyLabel.classList.add('sr-only')
        },
        () => {
          console.warn('Copy failed')
        }
      )
    },

    permalink(event) {
      try {
        this.setSelectionRange(0, this.value.length)
        this.select()
      } catch (err) {
        // continue execution if failed
      }
    },

    trackSocialShare(e, network) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'social share',
        social_share_network: formatData(network),
      })
    },
  },
  {
    init() {
      //data layer
      this.$node.querySelectorAll('[data-social-item').forEach((item) => {
        const network = item.getAttribute('data-network')
        item
          .querySelector('button')
          .addEventListener('click', (e) => this.trackSocialShare(e, network))
      })

      this._data.socialWindowRef = null
      this.addListener(this.getChildren('facebook'), this.facebook)
      this.addListener(this.getChildren('twitter'), this.twitter)
      this.addListener(this.getChildren('email'), this.email)
      this.addListener(this.getChildren('linkedin'), this.linkedin)
      this.addListener(this.getChildren('tumblr'), this.tumblr)
      this.addListener(this.getChildren('copy'), this.copy)
    },
    enabled() {},
    resized() {},
    mediaQueryUpdated() {
      // current media query is: A17.currentMediaQuery
    },
    disabled() {},
    destroy() {
      this.removeListener(this.getChildren('facebook'), this.facebook)
      this.removeListener(this.getChildren('twitter'), this.twitter)
      this.removeListener(this.getChildren('email'), this.email)
      this.removeListener(this.getChildren('linkedin'), this.linkedin)
      this.removeListener(this.getChildren('tumblr'), this.tumblr)
      this.removeListener(this.getChildren('copy'), this.copy)
    },
  }
)

export default SocialSharing
