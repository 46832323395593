import { createBehavior } from '@area17/a17-behaviors';

const scrollMarginBehavior = createBehavior(
  'scrollMargin',
  {
    applyScrollMarginTop(targetClass) {
      let anchorLinks = document.querySelectorAll('a[href^="#"]');
      anchorLinks.forEach(function (anchor) {
        let targetId = anchor.getAttribute('href').substring(1);
        let targetDiv = document.getElementById(targetId);
        if (targetDiv) {
          targetDiv.classList.add(targetClass);
        }
      });
    },
  },
  {
    init() {
        this.applyScrollMarginTop('scroll-margin-top');
    },
  }
);

export default scrollMarginBehavior;
