import { createBehavior } from '@area17/a17-behaviors'
import YouTubePlayer from 'youtube-player'
import Player from '@vimeo/player';

const videoPlayer = createBehavior(
  'videoPlayer',
  {
    initYoutubePlayer() {
      if (this.poster) {
        this.poster.addEventListener('click', () => {
          this.player = YouTubePlayer(this.$node.querySelector('.video'))
          this.player.loadVideoById(this.options.id)
          this.player.on('ready', () => {
            this.poster.style.transition = '.3s opacity'
            this.poster.style.opacity = 0
            setTimeout(() => {
              this.poster.remove()
            }, 300)
          })
          this.player.playVideo()
        })
      } else {
        this.player = YouTubePlayer(this.$node.querySelector('.video'))
        this.player.loadVideoById(this.options.id)
      }
    },
    initVimeoPlayer() {
      if (this.poster) {
        this.poster.addEventListener('click', () => {
          this.vimeoPlayer = new Player(this.$node.querySelector('.video'),{
              id: this.options.id,
          })
          this.vimeoPlayer.on('loaded', () => {
            this.poster.style.transition = '.3s opacity'
            this.poster.style.opacity = 0
            setTimeout(() => {
              this.poster.remove()
            }, 300)
          })
          this.vimeoPlayer.play()
        })
      } else {
        this.vimeoPlayer = new Player(this.$node.querySelector('.video'),{
            id: this.options.id,
        })
      }
    },

    initVideoPlayer() {
      if (this.poster && this.options.autoplay) {
        setTimeout(() => {
          this.poster.remove()
        }, 300)
      }
      
      if (this.poster) {
        this.poster.addEventListener('click', () => {
          this.poster.style.transition = '.3s opacity'
          this.poster.style.opacity = 0
          setTimeout(() => {
            this.poster.remove()
          }, 300)
          this.$node.querySelector('video').play()
        })
      }
    },
  },
  {
    init() {
      this.poster = this.getChild('poster')
      this.playerType = this.options.type
      if (this.options.file) {
        this.initVideoPlayer()
      } else {
        if (this.playerType === 'vimeo') {
          this.initVimeoPlayer()
        }else {
          this.initYoutubePlayer()
        }
      }
    },
  }
)

export default videoPlayer
