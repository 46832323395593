const containsSpecialCharacters = (str) => {
    var regex = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/g;
    return regex.test(str);
}

const formatData = (string) => {

    string = string.toLowerCase(); //lowercase
    let result = "";
    for (let i = 0; i < string.length; i++) {
      if (string[i] == "&") {
        //& replaced with and
        result += "and";
      } else if (string[i] == "_") {
        // _ replaced with a space
        result += " ";
      } else if (!containsSpecialCharacters(string[i])) {
        //only add is alpha numeric
        result += string[i];
      }
    }
    return result;
}

export default formatData
