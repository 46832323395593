import { createBehavior } from '@area17/a17-behaviors'

const VideoBackground = createBehavior(
    'VideoBackground',
    {
      initPlayer() {
        this.$player.play();
      },
  
      togglePlay(e) {
        e.preventDefault();
  
        if (this.isPlaying) {
          this.pause();
        } else {
          this.play();
        }
      },
  
      toggleMute(e) {
        if (e) {
          e.preventDefault();
        }
  
        if (this.isMuted) {
          this.unmute();
        } else {
          this.mute();
        }
      },
  
      play() {
        this.$player.play();
        this.isPlaying = true;
      },
  
      pause() {
        this.$player.pause();
        this.isPlaying = false;
      },
  
      handlePlay() {
        this.isPlaying = true;
      },
      handlePause() {
        this.isPlaying = false;
      },
  
      startIntersectionObserver() {
        const options = {
          threshold: [0.25, 0.75],
        };
  
        this.observer = new IntersectionObserver(this.handleObserver, options);
  
        this.observer.observe(this.$player);
      },
  
      handleObserver(entries) {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            this.play();
          } else if (this.isPlaying) {
            this.pause();
          }
        });
      },
    },
    {
      init() {
        // elems
        this.$player = this.getChild('player');
  
        this.initPlayer();
  
        this.$player.addEventListener('play', this.handlePlay, false);
        this.$player.addEventListener('pause', this.handlePause, false);
  
        if (window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
          this.pause();
        } else {
          this.startIntersectionObserver();
        }
      },
      enabled() {},
      resized() {},
      mediaQueryUpdated() {},
      disabled() {},
      destroy() {
        this.observer.disconnect();
      },
    }
  );
  
  export default VideoBackground;