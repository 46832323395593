import { createBehavior } from '@area17/a17-behaviors'
import * as focusTrap from 'focus-trap'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
  openNav,
  closeNav,
  animateGroup,
  animateBack,
  animateCloseSearch,
  animateSearchOpen,
} from '@animations/mobileNav'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || []

const trapConfig = {
  escapeDeactivates: false,
  allowOutsideClick: true,
}

let lastTitle = ''

const mobileNav = createBehavior(
  'mobileNav',
  {
    setEscape(e) {
      if (e.key === 'Escape') {
        this.closeAll()
      }
    },

    closeAll() {
      if (this.isNavOpen) {
        this.closeNav()
      }
      if (this.isSearchOpen) {
        this.closeSearch()
      }
    },
    openNav() {
      this.isNavOpen = true
      this.trap = focusTrap.createFocusTrap(this.navPanel, trapConfig)

      const begin = () => {
        disableBodyScroll(this.$node)
        this.navPanel.style.visibility = 'visible'
      }
      const complete = () => {
        this.trap.activate()
      }

      openNav(this, begin, complete)
    },

    closeNav(cb) {
      this.isNavOpen = false
      const begin = () => {}
      const complete = () => {
        this.navPanel.style.visibility = 'hidden'
        this.trap.deactivate()

        clearAllBodyScrollLocks()
        if (this.nestedTrap) {
          this.nestedTrap.deactivate()
        }
        if (cb) {
          cb()
        }
      }

      closeNav(this, begin, complete)
    },

    openGroup(section) {
      const group = section.parentElement.querySelector('.nav__group__panel')
      this.currentPanel = group
      this.nestedTrap = focusTrap.createFocusTrap(this.currentPanel, trapConfig)

      const begin = () => {}
      const complete = () => {
        this.nestedTrap.activate()
      }

      animateGroup(this, begin, complete)
    },

    goBack() {
      this.nestedTrap.deactivate()
      animateBack(this)
    },

    checkIsTablet() {
      const smBp = 767
      if (window.innerWidth > smBp) {
        this.isTablet = true
      } else {
        this.isTablet = false
      }
    },

    openSearch() {
      this.isSearchOpen = true
      this.trap = focusTrap.createFocusTrap(this.searchPanel, {
        ...trapConfig,
        initialFocus: this.searchPanel.querySelector('input'),
      })

      this.searchPanel.querySelector('input').focus()

      const begin = () => {
        this.trap.activate()
        disableBodyScroll(this.searchPanel)
      }
      const complete = () => {}

      if (this.isNavOpen) {
        this.closeNav(() => animateSearchOpen(this, begin, complete))
      } else {
        animateSearchOpen(this, begin, complete)
      }
    },

    closeSearch(cb) {
      this.isSearchOpen = false
      const begin = () => {}
      const complete = () => {
        clearAllBodyScrollLocks()
        if (cb) {
          cb()
        }
      }
      animateCloseSearch(this, begin, complete)
    },

    trackSimpleNavLinkClick(title) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: formatData(title),
      })
    },

    trackToggleSearch() {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: this.isSearchOpen ? 'open search' : 'close search',
      })
    },

    trackOpenAccordion(title) {
      lastTitle = title
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: formatData(title) + ' - open accordion',
      })
    },

    trackCloseAccordion() {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: lastTitle + '  - close accordion',
      })
    },

    trackSubLinkClick(sectionTitle, subLinkTitle) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: 'possibility - ' + formatData(sectionTitle) + ' - ' + formatData(subLinkTitle),
      })
    },

    trackFeatureClick(e) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: 'possibility - possibility - possibility',
      })
    },
  },
  {
    init() {
      // states
      this.isNavOpen = false
      this.isTablet = false
      this.isSearchOpen = false
      this.currentPanel = null

      // panel elems
      this.groupButtons = [...this.$node.querySelectorAll('.nav__group-button')]
      this.backButtons = this.getChildren('back')
      this.navPanel = this.getChild('nav')
      this.closeButtons = this.getChildren('close')

      // global elems
      this.menuButton = this.getChild('menu')
      this.tabletOverlay = this.getChild('tabletoverlay')

      // search elems
      this.searchButtons = this.getChildren('search')
      this.searchPanel = this.getChild('searchpanel')
      this.searchClose = this.getChild('searchclose')
      this.searchMenu = this.getChild('searchmenu')

      // switch to animation panel when necessary
      this.checkIsTablet()
      window.addEventListener('resize', () => {
        this.checkIsTablet()
      })

      // add listeners

      // search listeners
      this.searchButtons.forEach((button) => {
        button.addEventListener('click', () => this.openSearch())
      })
      this.searchClose.addEventListener('click', () => {
        this.closeSearch()
      })

      this.searchMenu.addEventListener('click', () => {
        this.closeSearch(() => this.openNav())
      })

      // panel buttons
      this.closeButtons.forEach((elem) => {
        elem.addEventListener('click', () => this.closeNav())
      })
      this.groupButtons.forEach((elem) => {
        elem.addEventListener('click', () => {
          this.openGroup(elem)
        })
      })
      this.backButtons.forEach((elem) => {
        elem.addEventListener('click', () => {
          this.goBack(elem)
        })
      })

      // set visibilkit to remove tabbing
      this.navPanel.style.visibility = 'hidden'
      const groups = this.$node.querySelectorAll('.nav__group__panel')
      groups.forEach((group) => {
        group.style.visibility = 'hidden'
      })

      // global events
      this.menuButton.addEventListener('click', () => this.openNav())
      document.addEventListener('keyup', this.setEscape)
      this.tabletOverlay.addEventListener('click', () => {
        this.closeAll()
      })

      window.addEventListener('swup:page-update', () => {
        this.closeAll()
      })

      //DATA LAYER
      //'simple' nav links
      this.$node.querySelectorAll('[data-simple-link]').forEach((item) => {
        const title = item.getAttribute('data-groupTitle')
        item.addEventListener('click', () =>
          this.trackSimpleNavLinkClick(title)
        )
      })

      //secondary links
      this.$node
        .querySelectorAll('[data-secondary-link]')
        .forEach((item) => {
          const title = item.getAttribute('data-secondaryTitle')
          item.addEventListener('click', () =>
            this.trackSimpleNavLinkClick(title)
          )
        })

      //accordion buttons
      this.$node
        .querySelectorAll('[data-accordion-button]')
        .forEach((item) => {
          const title = item.getAttribute('data-groupTitle')
          item.addEventListener('click', () => this.trackOpenAccordion(title))
        })
      //close accordion
      this.$node
        .querySelector('[data-mobilenav-back]')
        .addEventListener('click', this.trackCloseAccordion)

      //feature
      this.$node
        .querySelector('.mobile-feature')
        .addEventListener('click', (e) => this.trackFeatureClick(e))

      //submenu items
      this.$node
        .querySelectorAll('[data-submenu]')
        .forEach((item) => {
          item
            .querySelectorAll('[data-submenu-link]')
            .forEach((subitem) => {
              const subtitle = subitem.getAttribute('data-subsectionTitle')
              const linkTitle = subitem.getAttribute('data-subLinkTitle')
              subitem.addEventListener('click', () =>
                this.trackSubLinkClick(subtitle, linkTitle)
              )
            })
        })

      //search
      this.$node
        .querySelector('[data-mobilenav-searchclose]')
        .addEventListener('click', this.trackToggleSearch)
      this.$node.querySelectorAll('[data-mobilenav-search]').forEach((item) => {
        item.addEventListener('click', this.trackToggleSearch)
      })
    },
  }
)

export default mobileNav
