import { createBehavior } from '@area17/a17-behaviors'
import * as focusTrap from 'focus-trap'
import { disableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock'
import {
  animateCloseGroup,
  animateOpenGroup,
  closeSearch,
  openSearch,
} from '../animations/desktopNav'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || []

const desktopNav = createBehavior(
  'desktopNav',
  {
    setEscape(e) {
      if (e.key === 'Escape') {
        if (this.currentOpen !== null) {
          this.checkPanels(this.currentOpen)
        }
      }
    },

    setActive() {
      this.groupButtons.forEach((elem, index) => {
        if (index === this.currentOpen) {
          elem.classList.add('is-open')
        } else {
          elem.classList.remove('is-open')
        }
      })
    },

    openGroup(index) {
      disableBodyScroll(this.$node)
      this.currentOpen = index
      this.setActive()
      const section = this.groupButtons[index]
      const group = section.parentElement.querySelector('.nav__group__panel')
      group.style.visibility = 'visible'

      this.trap = focusTrap.createFocusTrap(group, {
        allowOutsideClick: true,
      })

      const begin = () => {
        this.isAnimating = true
      }

      const complete = () => {
        this.trap.activate()
        this.isAnimating = false
      }
      animateOpenGroup(group, this, begin, complete)
    },

    closeNav(index, cb) {
      this.currentOpen = null
      this.setActive()
      const section = this.groupButtons[index]
      const group = section.parentElement.querySelector('.nav__group__panel')

      const begin = () => {
        this.isAnimating = true
        clearAllBodyScrollLocks()
      }

      const complete = () => {
        this.trap.deactivate()
        this.isAnimating = false
        if (cb) {
          cb()
        }
      }

      animateCloseGroup(group, this, begin, complete)
    },

    openSearch() {
      this.currentOpen = 'search'
      this.setActive()
      this.trap = focusTrap.createFocusTrap(this.searchPanel, {
        allowOutsideClick: true,
        initialFocus: this.searchPanel.querySelector('input'),
      })
      const begin = () => {
        this.isAnimating = true
        this.trap.activate()
        disableBodyScroll(this.searchPanel)
      }
      const complete = () => {
        this.isAnimating = false
      }
      openSearch(this, begin, complete)
    },

    closeSearch(cb) {
      this.currentOpen = null
      this.setActive()
      const begin = () => {
        this.isAnimating = true
        clearAllBodyScrollLocks()
      }
      const complete = () => {
        this.trap.deactivate()
        this.isAnimating = false
        if (cb) {
          cb()
        }
      }
      closeSearch(this, begin, complete)
    },

    checkPanels(selectedIndex) {
      if (this.currentOpen === null) {
        if (selectedIndex === 'search') {
          this.openSearch()
        } else {
          this.openGroup(selectedIndex)
        }
        this.setActive(selectedIndex)
        return
      }

      if (this.currentOpen === selectedIndex) {
        if (selectedIndex === 'search') {
          this.closeSearch()
        } else {
          this.closeNav(selectedIndex)
        }
      } else {
        // we're going to to be selecting a search open from a group
        if (selectedIndex === 'search') {
          this.closeNav(this.currentOpen, () => this.openSearch())
        } else {
          if (this.currentOpen === 'search') {
            this.closeSearch(() => this.openGroup(selectedIndex))
          } else {
            this.closeNav(this.currentOpen, () => this.openGroup(selectedIndex))
          }
        }
        this.setActive(selectedIndex)
      }
    },

    trackSimpleNavLinkClick(title) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: formatData(title),
      })
    },

    trackToggleSearch() {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked:
          this.currentOpen == 'search' ? 'open search' : 'close search',
      })
    },

    trackToggleAccordion(title, self) {
      const button = this.groupButtons[this.currentOpen]
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked:
          button == self
            ? formatData(title) + ' - open accordion'
            : formatData(title) + '  - close accordion',
      })
    },

    trackSubLinkClick(sectionTitle, subLinkTitle) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: 'possibility - ' + formatData(sectionTitle) + ' - ' + formatData(subLinkTitle),
      })
    },

    trackFeatureClick() {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'top nav',
        link_clicked: 'possibility - possibility - possibility',
      })
    },
  },

  {
    init() {
      this.isAnimating = false
      this.groupButtons = [...this.$node.querySelectorAll('.nav__group-button')]
      this.overlay = document.querySelector('.bg-blur--overlay')
      this.searchPanel = this.getChild('searchpanel')
      this.openIndex = null
      this.searchButton = this.getChild('search')
      this.searchCloseButton = this.getChild('searchclose')
      this.currentOpen = null

      this.searchButton.addEventListener('click', () => {
        if (this.isAnimating) return
        this.checkPanels('search')
      })

      this.searchCloseButton.addEventListener('click', () => {
        if (this.isAnimating) return
        this.checkPanels('search')
      })

      this.groupButtons.forEach((elem, index) => {
        elem.addEventListener('click', () => {
          if (this.isAnimating) return
          this.checkPanels(index)
        })
      })

      this.overlay.addEventListener('click', () => {
        if (this.isAnimating) return
        this.checkPanels(this.currentOpen)
      })

      // a11y hide
      const groups = this.$node.querySelectorAll('.nav__group__panel')
      groups.forEach((group) => {
        // a11y hide
        group.style.visibility = 'hidden'
      })

      document.addEventListener('keyup', this.setEscape)

      window.addEventListener('swup:page-update', () => {
        if (this.currentOpen !== null) {
          this.checkPanels(this.currentOpen)
        }
      })

      //data layer

      //'simple' nav links
      this.$node.querySelectorAll('[data-simple-link]').forEach((item) => { 
        const title = item.getAttribute('data-groupTitle')
        item.addEventListener('click', () =>
          this.trackSimpleNavLinkClick(title)
        )
      })

      //secondary links
      this.$node
        .querySelectorAll('[data-secondary-link]') 
        .forEach((item) => {
          const title = item.getAttribute('data-secondaryTitle')
          if (title != null) {
            item.addEventListener('click', () =>
              this.trackSimpleNavLinkClick(title)
            )
          }
        })

      //search
      this.$node
        .querySelector('[data-desktopNav-searchclose]')
        .addEventListener('click', this.trackToggleSearch)
      this.$node
        .querySelector('[data-desktopNav-search]')
        .addEventListener('click', this.trackToggleSearch)
      this.overlay.addEventListener('click', this.trackToggleSearch)

      //accordion buttons
      this.$node
        .querySelectorAll('[data-accordion-button]') 
        .forEach((item) => {
          const title = item.getAttribute('data-groupTitle')
          item.addEventListener('click', () =>
            this.trackToggleAccordion(title, item)
          )
        })

      //feature
      this.$node
        .querySelector('[data-nav-feature]')
        .addEventListener('click', this.trackFeatureClick)

      //submenu items
      this.$node
        .querySelectorAll('[data-submenu]')
        .forEach((item) => {
          item
            .querySelectorAll('[data-submenu-link]') 
            .forEach((subitem) => {
              const subtitle = subitem.getAttribute('data-subsectionTitle')
              const linkTitle = subitem.getAttribute('data-subLinkTitle')
              subitem.addEventListener('click', () =>
                this.trackSubLinkClick(subtitle, linkTitle)
              )
            })
        })
    },
  }
)

export default desktopNav
