import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || [];

const PER_PAGE = 10;

const newsroomSearch = createBehavior(
  'newsroomSearch',
  {
    trackSearchResultClick(item) {
      const positionTitle = item.querySelector("[data-title]").getAttribute("data-title");
      const filter = this.$node.querySelector(".filters");
      const curPage = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageCurrent");
      const pagePosition = item.parentElement.getAttribute('data-list-position');
      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "search result click",
        search_section: "newsroom",
        result_clicked_title: formatData(positionTitle),
        result_clicked_position: ((+curPage - 1) * PER_PAGE) + +pagePosition
      });
    },
    trackSearchAction(actionType){
        const contentFilter = this.$node.querySelector('[data-filters-filter]').querySelector('select').value;
        const filter = this.$node.querySelector(".filters");
        const numResults = filter.querySelector(".filters__pagination").getAttribute('data-paginationInfoTotal');
        const curPage =  filter.querySelector(".filters__pagination").getAttribute('data-pageCurrent');
        const totalPages =  filter.querySelector(".filters__pagination").getAttribute('data-pageTotal');

        window.dataLayer.push({
          'event': 'search results load',
          'interaction_name': 'newsroom search results',
          'content_type_filter': contentFilter == "" ? "none" : formatData(contentFilter),
          'newsroom_sort': "most relevant",
          'search_results_number': formatData(numResults),
          'search_pages_returned': formatData(totalPages),
          'search_page_no': formatData(curPage),
          'search_type': !actionType ? "none" : formatData(actionType), // how the search result was triggered - filter, reset filters, scroll to a different result page, sort
        });
    },
  },

  {
    init() {
        // Track results on load
        this.trackSearchAction();
        //filter
        this.$node.querySelector('[data-filters-submit]').addEventListener('click', () => {this.trackSearchAction("filter")});
        //reset
        this.$node.querySelector('[data-filters-clear]').addEventListener('click', () => {this.trackSearchAction("reset")});

        //change page
        if(this.$node.querySelector('[data-page-next]') != null){
            this.$node.querySelector('[data-page-next]').addEventListener('click', () => {this.trackSearchAction("scroll to a different page")});
        }
        if(this.$node.querySelector('[data-page-prev]') != null){
            this.$node.querySelector('[data-page-prev]').addEventListener('click', () => {this.trackSearchAction("scroll to a different page")});
        }
        if (this.$node.querySelector('[data-pagination]').getAttribute('data-link')) {
            this.$node.querySelector('[data-dropdown-scroll]').addEventListener('change', () => {this.trackSearchAction("scroll to a different page")});
        }

        //results
        this.$node.querySelectorAll("[data-list-position]").forEach((item) => {
          item.addEventListener("click", (e) => { 
            this.trackSearchResultClick(item) 
          });
        });
    },
  }
)

export default newsroomSearch
