import './styles.scss'

import { manageBehaviors } from '@area17/a17-behaviors'
import { focusDisplayHandler } from '@area17/a17-helpers'
import { ios100vhFix } from '@area17/a17-helpers'

import * as Behaviors from '@behaviors/index'
import pjax from '@utils/pjax'
import 'scroll-shadow-element'


window.A17 = window.A17 || {}

window.dataLayer = window.dataLayer || [];


const readyUp = () => {

  //data layer push on load
  const swup = document.getElementById('swup');
  let needToCheckWords = false;
  let wordCount = 0;
  if(swup != null){
    if(swup.getAttribute('data-content-structural-type') != null){
      let structuralType = swup.getAttribute('data-content-structural-type');
      if(structuralType != null){
        if(structuralType == "client stories" || structuralType == "insights" || structuralType == "newsroom pages" || structuralType == "campaigns"){
          needToCheckWords = true;
          document.querySelectorAll(".block--rich-text").forEach((item) => {
            wordCount += item.textContent.split(" ").length
          })
        }
      }
    }
    const layerObject = {
      ...((swup.getAttribute('data-industry-group') != null) && {'industry_group': swup.getAttribute('data-industry-group')}),
      ...((swup.getAttribute('data-industry') != null) && {'industry': swup.getAttribute('data-industry')}),
      ...((swup.getAttribute('data-services-group') != null) && {'services_group': swup.getAttribute('data-services-group')}),
      ...((swup.getAttribute('data-services') != null) && {'services': swup.getAttribute('data-services')}),
      ...((swup.getAttribute('data-global-shift') != null) && {'global_shift': swup.getAttribute('data-global-shift')}),
      ...((swup.getAttribute('data-content-all-tags') != null) && {'content_all_tags': swup.getAttribute('data-content-all-tags')}),
      ...((swup.getAttribute('data-content-visible-tags') != null) && {'content_visible_tags': swup.getAttribute('data-content-visible-tags')}),
      ...((swup.getAttribute('data-content-structural-type') != null) && {'content_structural_type': swup.getAttribute('data-content-structural-type')}),
      ...((swup.getAttribute('data-content-article-type') != null) && {'content_article_type': swup.getAttribute('data-content-article-type')}),
      ...((swup.getAttribute('data-content-entry-type') != null) && {'content_entry_type': swup.getAttribute('data-content-entry-type')}),
      ...((swup.getAttribute('data-content-date-published') != null) && {'content_date_published': swup.getAttribute('data-content-date-published')}),
      ...((swup.getAttribute('data-content-date-modified') != null) && {'content_date_modified': swup.getAttribute('data-content-date-modified')}),
      ...((swup.getAttribute('data-content-author') != null) && {'content_author': swup.getAttribute('data-content-author')}),
      ...(needToCheckWords && {'content_length': wordCount}),
      ...((swup.getAttribute('data-gated-download-flag') != null) && {'gated_download_flag': swup.getAttribute('data-gated-download-flag')}),
    }
    if(Object.keys(layerObject).length > 0){ //only push if there are values
      window.dataLayer.push(layerObject);
    }
  }

  // Doc: https://github.com/area17/a17-behaviors/wiki/focusDisplayHandler
  focusDisplayHandler()

  function setVh() {
    const vh = document.documentElement.clientHeight * 0.01
    document.documentElement.style.setProperty('--vh', `${vh}px`)
  }

  window.addEventListener('resize', setVh)
  window.addEventListener('setVh', () => {
    console.log('triggering set vh')
    setVh()
  })

  setVh()

  // flag prefers reduced motion
  window.A17.reduceMotion = window.matchMedia(
    '(prefers-reduced-motion: reduce)'
  ).matches

  // init pjax
  window.A17.pjax = pjax()

  manageBehaviors.init(Behaviors)
  removeReady()
}

const removeReady = () => {
  document.removeEventListener('DOMContentLoaded', readyUp)
  
}

document.addEventListener('DOMContentLoaded', readyUp)
