import { createBehavior } from '@area17/a17-behaviors'

const background_color_2 = '#00172d'
const background_color_3 = '#ECF0F6'
const text_color_3 = '#FFFFFF'

let offset = 0
const border_rad = 30
const sm_breakpoint = 539
const md_breakpoint = 767
const xs_bottom_border = 35
const bottom_border = 20

const themes = createBehavior(
  'themes',
  {},
  {
    init() {
      let count = 0
      const num_cards = this.$node.querySelectorAll('a').length
      this.$node.querySelectorAll('a').forEach((e) => {
        //change colors
        // count += 1
        if ((count - 1) % 3 == 0) {
          //default color
        } else if (count % 3 == 0) {
          //   e.style.backgroundColor = background_color_2;
          //   e.style.color = text_color_3;
        } else {
          //   e.style.background = background_color_3;
          //   e.style.color = background_color_2;
        }

        //if you want to exclude the last card then it's count < num_cards
        if (count <= num_cards) {
          e.addEventListener('mouseenter', () => {
            //do nothing at smallest breakpoint
            if (window.innerWidth <= sm_breakpoint) return
            const inner_text = e.querySelector('.themes-card_content__tertiary')
            const inner_text_height =
              (inner_text.getBoundingClientRect().height - border_rad) * -1
            e.style.transform = `translateY(${inner_text_height}px)`
          })

          e.addEventListener('mouseleave', () => {
            if (window.innerWidth <= sm_breakpoint) return
            e.style.transform = `translateY(${0}px)`
          })
        }
      })

      const onResize = () => {
        count = 0
        offset = 0
        const elements = this.$node.querySelectorAll('a')
        elements.forEach((e) => {
          count += 1
          const inner_text = e.querySelector('.themes-card_content__tertiary')
          const inner_text_height = inner_text.getBoundingClientRect().height
          e.style.top = `${offset}px`
          //update offset
          const rect = e.getBoundingClientRect()
          if (window.innerWidth <= sm_breakpoint) {
            offset = offset + rect.height - xs_bottom_border
          } else {
            //QA: added bottom border to cards at xs and sm
            if (window.innerWidth <= md_breakpoint) {
              offset = offset + rect.height - inner_text_height - bottom_border
            } else {
              offset = offset + rect.height - inner_text_height
            }
          }
        })

        //need to calculate container div height
        if (window.innerWidth <= sm_breakpoint) {
          this.$node.style.height = `${offset + border_rad}px`
        } else {
          this.$node.style.height = `${
            offset +
            elements[elements.length - 1]
              .querySelector('.themes-card_content__tertiary')
              .getBoundingClientRect().height
          }px`
        }
      }
      onResize()

      window.addEventListener('resize', onResize)
    },
  }
)

export default themes
