import { createBehavior } from "@area17/a17-behaviors";
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || [];

const PER_PAGE = 20;

const careerSearch = createBehavior(
  "careerSearch",
  {
    trackSearchAction(actionType) {
      localStorage.setItem('CAREER_SEARCH_TYPE', JSON.stringify(formatData(actionType)));
      this.searchType = formatData(actionType);
      const searchTerm = this.$node.querySelector(
        "[data-searchinput-input]"
      ).value;
      const locationsFilter =
        this.$node.querySelector("#filterLocations").value;
      const employmentTypesFilter = this.$node.querySelector(
        "#filterEmploymentTypes"
      ).value;
      const roleTypesFilter =
        this.$node.querySelector("#filterRoleTypes").value;

      const filter = this.$node.querySelector(".filters");
      const numResults = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-paginationInfoTotal");
      const curPage = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageCurrent");
      const totalPages = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageTotal");

      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "career search results",
        search_term: searchTerm == "" ? "none" : formatData(searchTerm),
        search_results_number: formatData(numResults),
        search_pages_returned: formatData(totalPages),
        search_page_no: formatData(curPage),
        locations_filter: locationsFilter == "" ? "none" : formatData(locationsFilter),
        employment_types_filter:
          employmentTypesFilter == "" ? "none" : formatData(employmentTypesFilter),
        role_types_filter: roleTypesFilter == "" ? "none" : formatData(roleTypesFilter),
        search_sort: "most relevant",
        search_type: this.searchType, // how the search result was triggered - filter, reset filters, scroll to a different result page, sort
      });
    },

    trackResults() {
      this.searchType = JSON.parse(localStorage.getItem('CAREER_SEARCH_TYPE')) || 'search';
      const searchTerm = this.$node.querySelector(
        "[data-searchinput-input]"
      ).value;

      const filter = this.$node.querySelector(".filters");
      const numResults = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-paginationInfoTotal");
      const curPage = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageCurrent");
      const totalPages = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageTotal");

      const locationsFilter =
        this.$node.querySelector("#filterLocations").value;
      const employmentTypesFilter = this.$node.querySelector(
        "#filterEmploymentTypes"
      ).value;
      const roleTypesFilter =
        this.$node.querySelector("#filterRoleTypes").value;

      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "career search results",
        search_term: searchTerm == "" ? "none" : formatData(searchTerm),
        search_results_number: formatData(numResults),
        search_pages_returned: formatData(totalPages),
        search_page_no: formatData(curPage),
        locations_filter: locationsFilter == "" ? "none" : formatData(locationsFilter),
        employment_types_filter:
          employmentTypesFilter == "" ? "none" : formatData(employmentTypesFilter),
        role_types_filter: roleTypesFilter == "" ? "none" : formatData(roleTypesFilter),
        search_sort: "most relevant",
        search_type: this.searchType
      });
      localStorage.removeItem('CAREER_SEARCH_TYPE')
    },

    trackSearchResultClick(item) {
      const searchTerm = this.$node.querySelector(
        "[data-searchinput-input]"
      ).value;
      const positionTitle = item.getAttribute("data-title");
      const filter = this.$node.querySelector(".filters");
      const curPage = filter
        .querySelector(".filters__pagination")
        .getAttribute("data-pageCurrent");
      const pagePosition = item.parentElement.getAttribute('data-list-position');
      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "search result click",
        search_section: "careers",
        search_term: searchTerm == "" ? "none" : formatData(searchTerm),
        result_clicked_title: formatData(positionTitle),
        search_type: this.searchType,
        result_clicked_position: ((+curPage - 1) * PER_PAGE) + +pagePosition
      });
    },
  },

  {
    init() {
      //track search results info on page load
      this.trackResults();

      //filter
      this.$node
        .querySelector("[data-filters-submit]")
        .addEventListener("click", () => {
          this.trackSearchAction("filter");
        });
      //reset
      this.$node
        .querySelector("[data-filters-clear]")
        .addEventListener("click", () => {
          this.trackSearchAction("reset");
        });

      //change page
      if (this.$node.querySelector("[data-page-next]") != null) {
        this.$node
          .querySelector("[data-page-next]")
          .addEventListener("click", () => {
            this.trackSearchAction("scroll to a different page");
          });
      }
      if (this.$node.querySelector("[data-page-prev]") != null) {
        this.$node
          .querySelector("[data-page-prev]")
          .addEventListener("click", () => {
            this.trackSearchAction("scroll to a different page");
          });
      }
      if (this.$node.querySelector("[data-pagination]").getAttribute("data-link")) {
        this.$node
          .querySelector("[data-dropdown-scroll]")
          .addEventListener("change", () => {
            this.trackSearchAction("scroll to a different page");
          });
      }

      //results
      this.$node.querySelectorAll("[data-job-card]").forEach((item) => {
        item.addEventListener("click", (e) => { 
          this.trackSearchResultClick(item) 
        });
      });
    },
  }
);

export default careerSearch;
