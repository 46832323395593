import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || [];
const PER_PAGE = 10;

const mainSearch = createBehavior(
  'mainSearch',
  {
    trackSearchAction(actionType){
      localStorage.setItem('SEARCH_TYPE', JSON.stringify(formatData(actionType)));
      this.searchType = formatData(actionType);
      const contentFilter = this.$node.querySelector('[data-filters-filter]').querySelector('select').value;
      const searchTerm = this.$node.querySelector('[data-searchinput-input]').value;

      const filter = this.$node.querySelector(".filters");
      const numResults = filter.querySelector(".filters__pagination").getAttribute('data-paginationInfoTotal');
      const curPage =  filter.querySelector(".filters__pagination").getAttribute('data-pageCurrent');
      const totalPages =  filter.querySelector(".filters__pagination").getAttribute('data-pageTotal');

      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'main search results',
        'search_term': searchTerm == "" ? "none" : formatData(searchTerm),
        'content_type_filter': contentFilter == "" ? "none" : formatData(contentFilter),
        'search_results_number': formatData(numResults),
        'search_pages_returned': formatData(totalPages),
        'search_page_no': formatData(curPage),
        'search_sort': "most relevant",
        'search_type': this.searchType, // how the search result was triggered - filter, reset filters, scroll to a different result page, sort
        });
    },

    trackResults(){
      this.searchType = JSON.parse(localStorage.getItem('SEARCH_TYPE')) || 'search';
      const searchTerm = this.$node.querySelector('[data-searchinput-input]').value;
      const contentFilter = this.$node.querySelector('[data-filters-filter]').querySelector('select').value;

      const filter = this.$node.querySelector(".filters");
      const numResults = filter.querySelector(".filters__pagination").getAttribute('data-paginationInfoTotal');
      const curPage =  filter.querySelector(".filters__pagination").getAttribute('data-pageCurrent');
      const totalPages =  filter.querySelector(".filters__pagination").getAttribute('data-pageTotal');

      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'main search results',
        'search_term': searchTerm == "" ? "none" : formatData(searchTerm),
        'content_type_filter': contentFilter == "" ? "none" : formatData(contentFilter),
        'search_results_number': formatData(numResults),
        'search_pages_returned': formatData(totalPages),
        'search_page_no': formatData(curPage),
        'search_sort': "most relevant",
        'search_type': this.searchType
      });
      localStorage.removeItem('SEARCH_TYPE')
    },

    trackSearchResultClick(item){
      const searchTerm = this.$node.querySelector('[data-searchinput-input]').value;
      const resultTitle = item.querySelector(".search-result__title").getAttribute('data-title');
      const filter = this.$node.querySelector(".filters");
      const curPage =  filter.querySelector(".filters__pagination").getAttribute('data-pageCurrent');
      const pagePosition = item.parentElement.getAttribute('data-list-position');
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'search result click',
        'search_section': 'main',
        'search_term': searchTerm == "" ? "none" : formatData(searchTerm),
        'result_clicked_title': formatData(resultTitle),
        'search_type': this.searchType,
        'result_clicked_position': ((+curPage - 1) * PER_PAGE) + +pagePosition
      });
    },
  },

  {
    init() {
      //track search results info on page load
      this.trackResults();

      //search
      this.$node.querySelector('[data-searchinput-searchbutton]').addEventListener('click', () => {this.trackSearchAction("search")});
      //filter
      this.$node.querySelector('[data-filters-submit]').addEventListener('click', () => {this.trackSearchAction("filter")});
      //reset
      this.$node.querySelector('[data-filters-clear]').addEventListener('click', () => {this.trackSearchAction("reset")});

      //results
      this.$node.querySelectorAll('.search-result').forEach((item) => {
          item.addEventListener('click', (e) => {this.trackSearchResultClick(item)})
      })

      //change page
      //need to check if pagination buttons exist
      if(this.$node.querySelector('[data-page-next]') != null){
        this.$node.querySelector('[data-page-next]').addEventListener('click', () => {this.trackSearchAction("scroll to a different page")});
      }
      if(this.$node.querySelector('[data-page-prev]') != null){
        this.$node.querySelector('[data-page-prev]').addEventListener('click', () => {this.trackSearchAction("scroll to a different page")});
      }
      if (this.$node.querySelector('[data-pagination]').getAttribute('data-link')) {
        this.$node.querySelector('[data-dropdown-scroll]').addEventListener('change', () => {this.trackSearchAction("scroll to a different page")});
      }
    },
  }
)

export default mainSearch
