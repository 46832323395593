import { createBehavior } from "@area17/a17-behaviors";
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || [];

const momentStat = createBehavior(
  "momentStat",
  {
  
    trackLink() {
      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "card click",
        card_title: formatData(this.$node.getAttribute("data-title")),
        card_url: this.$node.getAttribute("data-link"),
      });
    },
  },
  {
    init() {
      this.$node.addEventListener("click", this.trackLink);
    },
  }
);

export default momentStat;
