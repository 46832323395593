import { createBehavior } from '@area17/a17-behaviors'
import Bricks from 'bricks.js'

const sizes = [
  { columns: 1, gutter: 20 },
  { mq: '767px', columns: 2, gutter: 20 },
]

const xl_breakpoint = 1279

const brickLayout = createBehavior(
  'brickLayout',
  {},
  {
    init() {
      const instance = Bricks({
        sizes: sizes,
        container: this.$node,
        packed: 'data-packed',
      }).resize(true)

      const triggerResize = () => {
        if (window.innerWidth >= xl_breakpoint) {
          var min_height = -1
          //get min heights
          this.$node.querySelectorAll('a').forEach((e) => {
            let rect = e.getBoundingClientRect()
            if (rect.height < min_height || min_height == -1) {
              min_height = rect.height
            }
          })
          //set tall cards to 1.5x the min height
          document
            .querySelectorAll('.moment-stat, .moment-quote')
            .forEach(function (e) {
              e.style.minHeight = `${1.5 * min_height}px`
            })
        } else {
          document
            .querySelectorAll('.moment-stat, .moment-quote')
            .forEach(function (e) {
              e.style.minHeight = 'auto'
            })
        }
        instance.pack()
        instance.update()
      }
      triggerResize()
      window.addEventListener('resize', triggerResize)
    },
  }
)

export default brickLayout
