import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

const areaActiveClass = 'is-active'
const buttonActiveClass = 'is-active'

window.dataLayer = window.dataLayer || []

let prevForm = ''

const tabs = createBehavior(
  'tabs',
  {
    setActiveTab(activeIndex) {
      this.tabs.forEach((tab, index) => {
        const contentArea = this.contentAreas[index]
        if (activeIndex === index) {
          tab.classList.add(buttonActiveClass)
          contentArea.classList.add(areaActiveClass)
        } else {
          tab.classList.remove(buttonActiveClass)
          contentArea.classList.remove(areaActiveClass)
        }
      })
    },

    trackFormSwitch() {
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'contact form switch',
        'contact_form_current': prevForm, 
        'contact_form_switched_to': formatData(this.$node
          .querySelector('.is-active')
          .getAttribute('data-title')),
      })
      prevForm = formatData(this.$node
        .querySelector('.is-active')
        .getAttribute('data-title'))
    },

    trackFormField(item) {
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'form interaction',
        'form_name': formatData(this.$node
          .querySelector('.is-active')
          .getAttribute('data-title')),
        'form_field': formatData(item.getAttribute('data-label')),
      })
    },

    trackFormError() {
      if (this.$node.querySelector('.fui-alert-error') != null) {
        let errorMessage = 'couldn’t save submission due to errors'
        const error = this.$node.querySelector('.fui-error-message')
        if (error != null) {
          errorMessage = error.innerHTML
          const field = error.parentElement.getAttribute('data-label')
          window.dataLayer.push({
            'event': 'visitor interaction',
            'interaction_name': 'form error',
            'form_name': formatData(this.$node
              .querySelector('.is-active')
              .getAttribute('data-title')),
            'form_field': formatData(field),
            'form_error': formatData(errorMessage),
          })
        } else {
          window.dataLayer.push({
            'event': 'virtual page view',
            'interaction_name': 'form error',
            'form_name': formatData(this.$node
              .querySelector('.is-active')
              .getAttribute('data-title')),
            'form_field': 'form',
            'form_error': formatData(errorMessage),
          })
        }
      }
    },

    trackFormSubmit() {
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'form submit',
        'form_name': formatData(this.$node
          .querySelector('.is-active')
          .getAttribute('data-title')),
      })
    },

    trackSubmitSuccess() {
      window.dataLayer.push({
        'event': 'virtual page view',
        'form_submit_result': 'success',
      })
    },

    setActiveTabFromFormResponse() {
      let contentAreaTab;
      if (this.$node.querySelector('.fui-alert-success') != null) {
        contentAreaTab = this.$node.querySelector('.fui-alert-success').closest('.tabs__content-area');
      }
      if (this.$node.querySelector('.fui-alert-error') != null) {
        contentAreaTab = this.$node.querySelector('.fui-alert-error').closest('.tabs__content-area');
      }
      const activeform = contentAreaTab.getAttribute('data-tab-form-slug');
      const activeTabButton = this.$node.querySelector('[data-tab-tab-slug='+activeform+']');

      this.tabs.forEach((tab, index) => {
        const contentArea = this.contentAreas[index]
        tab.classList.remove(buttonActiveClass)
        contentArea.classList.remove(areaActiveClass)
      })
      activeTabButton.classList.add(buttonActiveClass)
      contentAreaTab.classList.add(areaActiveClass)
    },
  },
  {
    init() {
      //page reloads after submit so check to see if submit success
      if (this.$node.querySelector('.fui-alert-success') != null) {
        this.trackSubmitSuccess()
      }

      prevForm = formatData(this.$node
        .querySelector('.is-active')
        .getAttribute('data-title'))

      this.tabs = this.getChildren('tab')
      this.contentAreas = [
        ...this.$node.querySelectorAll('.tabs__content-area'),
      ]
      this.tabs.forEach((tab, index) => {
        tab.addEventListener('click', () => {
          this.setActiveTab(index)
          this.trackFormSwitch()
        })
      })
      
      if ((this.$node.querySelector('.fui-alert-success') != null) || (this.$node.querySelector('.fui-alert-error')!= null)) {
        this.setActiveTabFromFormResponse()
      }

      //checkbox
      this.$node
        .querySelectorAll('.form-input__checkbox-label')
        .forEach((item) => {
          item.addEventListener('click', () => this.trackFormField(item))
        })

      //text field
      this.$node
        .querySelectorAll('.form-input__field input')
        .forEach((item) => {
          item.addEventListener('change', () => this.trackFormField(item))
        })

      //radio
      this.$node
        .querySelectorAll('.form-input__radio input')
        .forEach((item) => {
          item.addEventListener('click', () => this.trackFormField(item))
        })

      //dropdown
      this.$node
        .querySelectorAll('.form-input__field select')
        .forEach((item) => {
          item.addEventListener('change', () => this.trackFormField(item))
        })

      //text area
      this.$node
        .querySelectorAll('.text-area__field textarea')
        .forEach((item) => {
          item.addEventListener('change', () => this.trackFormField(item))
        })

      //file upload
      this.$node.querySelectorAll('.form-upload input').forEach((item) => {
        item.addEventListener('change', () => this.trackFormField(item))
      })

      //submit
      this.$node
        .querySelectorAll('[data-submit-action="submit"]')
        .forEach((item) => {
          item.addEventListener('click', () => this.trackFormSubmit())
        })

      this.$node.querySelectorAll('.fui-form').forEach((item) => {
        //error
        item.addEventListener('onFormieSubmitError', (e) => {
          this.trackFormError(e)
        })
        item.addEventListener('onFormieValidate', (e) => {
          this.trackFormError(e)
        })

        //submit
        item.addEventListener('onAfterFormieSubmit', (e) => {
          this.trackFormSubmit(e)
        })
      })
    },
  }
)

export default tabs
