import { createBehavior } from '@area17/a17-behaviors'

const searchInput = createBehavior(
  'searchInput',
  {},

  {
    init() {
      this.clearButton = this.getChild('clear')
      this.input = this.getChild('input')
      if (this.clearButton) {
        this.clearButton.addEventListener('click', () => {
          this.input.value = ''
          this.input.focus()
        })
      }
    },
  }
)

export default searchInput
