import { createBehavior } from '@area17/a17-behaviors'

const chipList = createBehavior(
  'chipList',
  {
    revealNext() {
      let moveFlag = false
      this.buttons.forEach((currentElem) => {
        const bounding = currentElem.getBoundingClientRect()
        if (
          moveFlag === false &&
          bounding.right >
            (window.innerWidth || document.documentElement.clientWidth)
        ) {
          const move = (window.innerWidth - bounding.right) * -1

          this.nav.scrollLeft += move + 40
          moveFlag = true
        }
      })
    },

    checkIfEnd() {
      if (this.nav.scrollLeft === this.nav.scrollWidth - this.nav.offsetWidth) {
        this.$node.classList.add('is-end')
      } else {
        this.$node.classList.remove('is-end')
      }
    },
  },
  {
    init() {
      this.buttons = [...this.$node.querySelectorAll('.chip')]
      this.nav = this.getChild('nav')
      this.isEnd = false
      this.nextButton = this.$node.querySelector('.chip-list__next')
      this.checkIfEnd()

      window.addEventListener('resize', () => {
        this.checkIfEnd()
      })

      this.nextButton.addEventListener('click', () => {
        this.revealNext()
      })

      this.nav.addEventListener('scroll', (e) => {
        this.checkIfEnd()
      })
    },
  }
)

export default chipList
