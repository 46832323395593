import { createBehavior } from '@area17/a17-behaviors'
import { slideDown, slideUp, slideStop } from 'slide-anim'

const ACITVE_CLASS = 'is-active'

const accordion = createBehavior(
  'accordion',
  {
    open() {
      slideStop(this.content)
      slideDown(this.content)
      this.button.setAttribute('aria-expanded', true)
      this.button.classList.add(ACITVE_CLASS)
    },
    close() {
      slideStop(this.content)
      slideUp(this.content)
      this.button.setAttribute('aria-expanded', false)
      this.button.classList.remove(ACITVE_CLASS)
    },
    toggleAccordion() {
      if (this.isExpanded) {
        this.close()
      } else {
        this.open()
      }
      this.isExpanded = !this.isExpanded
    },
  },
  {
    init() {
      this.isExpanded = false
      this.button = this.getChild('trigger')
      this.content = this.getChild('content')
      this.content.style.display = 'none'
      this.button.setAttribute('aria-expanded', false)
      this.button.addEventListener('click', () => {
        this.toggleAccordion()
      })
    },
  }
)

export default accordion
