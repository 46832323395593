import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

const SHOW_LABEL = 'Show filters'
const HIDE_LABEL = 'Hide filters'

window.dataLayer = window.dataLayer || []

const filters = createBehavior(
  'filters',
  {
    clearFilters() {
      this.clearApply()
      this.filters.forEach((elem) => {
        const filter = elem.querySelector('select')
        filter.value = ''
      })
      setTimeout(() => {
        this.$node.closest('form').submit()
      })
    },
    clearApply() {
      this.applyCount = 0
      this.updateApplyCountText()
    },

    updateApplyCount(index) {
      if (!this.applyCount.includes(index)) {
        this.applyCount.push(index)
        this.updateApplyCountText()
      }
    },

    applyFilters() {
      this.$node.closest('form').submit();
    },

    updateApplyCountText() {
      if (this.applyCount === 0) {
        this.applyButton.innerText = `Apply`
      } else {
        this.applyButton.innerText = `Apply (${this.applyCount.length})`
      }
    },

    // toggleFilters() {
    //   if (this.isOpen) {
    //     // hide content
    //     this.filterContent.style.display = 'none'
    //     // this.toggleButton.setAttribute('aria-pressed', false)
    //     this.toggleText.innerText = SHOW_LABEL
    //   } else {
    //     // show content
    //     this.filterContent.style.display = 'block'
    //     // this.toggleButton.setAttribute('aria-pressed', true)
    //     this.toggleText.innerText = HIDE_LABEL
    //   }
    //   this.isOpen = !this.isOpen
    // },
  },

  {
    init() {
      this.isOpen = false
      this.applyCount = []

      this.filterContent = this.getChild('content')
      this.applyButton = this.getChild('submit')
      this.clearButton = this.getChild('clear')
      // this.toggleButton = this.getChild('toggle')
      this.toggleText = this.getChild('toggletext')
      this.filters = this.getChildren('filter')

      // if filters are not added we dont do anything beyond here
      if (this.filters === null) return

      this.clearButton.addEventListener('click', this.clearFilters)

      // this.toggleButton.setAttribute('aria-pressed', false)
      // this.filterContent.style.display = 'none'
      // this.toggleButton.addEventListener('click', (e) => {
      //   this.toggleFilters()
      // })
      this.filters.forEach((elem, index) => {
        const filter = elem.querySelector('select')
        filter.addEventListener('change', () => {
          this.updateApplyCount(index)
          this.applyFilters()
        })
      })
    },
  }
)

export default filters
