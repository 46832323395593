import {createBehavior} from '@area17/a17-behaviors';

window.dataLayer = window.dataLayer || [];

const sortBy = createBehavior(
    'sortBy',
    {
        saveScrollPosition() {
            sessionStorage.setItem('scrollPosition', window.scrollY || document.documentElement.scrollTop);
        },

        handleSortByChange() {
            document.querySelector('button[data-filters-submit]').click();
        },
    },
    {
        init() {
            this.$node.addEventListener('change', () => {
                this.handleSortByChange();
                this.saveScrollPosition();
            });
            window.addEventListener('load', () => {
                window.scrollTo(0, sessionStorage.getItem('scrollPosition') || 0);
                sessionStorage.removeItem('scrollPosition');
            });

            window.addEventListener('beforeunload', () => {
                this.saveScrollPosition();
            });
        },
    }
);

export default sortBy;
