import { createBehavior } from '@area17/a17-behaviors'

const fileUpload = createBehavior(
    'fileUpload',
    {
        handleDragOver(e){
            e.preventDefault();

            this.isDragging = true
            e.currentTarget.classList.add('bg-black', 'bg-opacity-5')
        },

        handleDragLeave(e){
            e.preventDefault();
            this.isDragging = false
            e.currentTarget.classList.remove('bg-black', 'bg-opacity-5')
        },

        handleDrop(e){
            e.preventDefault();
            e.currentTarget.classList.remove('bg-black', 'bg-opacity-5')

            const files = e.dataTransfer.files

            this.handleFiles(files)
        },

        handleInputChange(){
            const files = this.$input.files

            this.handleFiles(files)
        },

        updateValue(){
            if (this.$input && this.$input.files) {
            //   this.$emit('input', this.$input.files[0])
            }
        },

        handleFiles(files) {
            this.hasError = false;

            if (files.length === 1) {
                const file = files[0];
                const fileSize = file.size / 1024 / 1024; // in MiB

                if (fileSize > this.maxFileSize) {
                    this.hasError = true;
                    this.uploadedFile = null;
                    this.isDragging = false;

                    this.updateUI(file);

                    return false
                }

                this.updateUI(file);
            }
        },

        updateUI(file){
            this.$selectedIconCheck.classList.toggle('form-upload__selected-icon--show', !this.hasError);
            this.$selectedIconError.classList.toggle('form-upload__selected-icon--show', this.hasError);
            this.$drop.classList.add('form-upload__drop-hide')

            this.$selectedName.textContent = file.name;
            this.$selected.classList.add('form-upload__selected--show');
            this.$drop.classList.add('form-upload__drop--hide')
        },

        resetInput(){
            this.$selectedName.textContent = '';
            this.$selected.classList.remove('form-upload__selected--show');
            this.$drop.classList.remove('form-upload__drop--hide')
        }
    },
    {
        init() {
            this.$input = this.getChild('input');
            this.$drop = this.getChild('drop');
            this.$selected = this.getChild('selected');
            this.$selectedIconError = this.getChild('selected-icon-error');
            this.$selectedIconCheck = this.getChild('selected-icon-check');
            this.$selectedName = this.getChild('selected-name');
            this.$selectedRemove = this.$selected.querySelector('button')

            this.isDragging = false;
            this.maxFileSize = this.options.size;
            this.allowedFiles = this.$input.accept;
            this.uploadedFile = false;

            this.$selectedRemove.addEventListener('click', this.resetInput, false);

            this.$input.addEventListener('change', this.handleInputChange, false);
            this.$input.addEventListener('input', this.updateValue, false);

            this.$drop.addEventListener('dragover', this.handleDragOver, false);
            this.$drop.addEventListener('dragleave', this.handleDragLeave, false);
            this.$drop.addEventListener('drop', this.handleDrop, false);
        },
    }
)

export default fileUpload
