import { createBehavior } from '@area17/a17-behaviors'
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || []

const footer = createBehavior(
  'footer',
  {
    trackSimpleNavLinkClick(title) {
      window.dataLayer.push({
        event: 'visitor interaction',
        interaction_name: 'main nav click',
        top_or_bottom_nav: 'bottom nav',
        link_clicked: formatData(title),
      })
    },
  },
  {
    init() {
      //DATA LAYER
      //primary items
      this.$node
        .querySelectorAll('[data-primary-item]')
        .forEach((item) => {
          const title = item.getAttribute('data-primaryTitle')
          item.addEventListener('click', () =>
            this.trackSimpleNavLinkClick(title)
          )
        })

      //secondary items
      this.$node
        .querySelectorAll('[data-secondary-item]')
        .forEach((item) => {
          const title = item.getAttribute('data-secondaryTitle')
          item.addEventListener('click', () =>
            this.trackSimpleNavLinkClick(title)
          )
        })

      //social
      this.$node
        .querySelectorAll('[data-social-item]')
        .forEach((item) => {
          const title = item.getAttribute('data-socialTitle')
          item.addEventListener('click', () =>
            this.trackSimpleNavLinkClick(title)
          )
        })
    },
  }
)

export default footer
