import { createBehavior } from '@area17/a17-behaviors'
import { getOffset } from '@area17/a17-helpers'

const services = createBehavior(
  'services',
  {
    setScrollPos() {
      this.winScrollPos = window.scrollY
      const wrapperOffset = getOffset(this.wrapper)
      this.scrollPos = this.winScrollPos - wrapperOffset.top + this.navHeight
    },

    updateValues() {
      if (window.innerWidth >= 1023) {
        this.navHeight = 104
      } else {
        this.navHeight = 88
      }
      this.pageHeight = window.innerHeight - this.navHeight

      if (window.innerWidth >= 539) {
        this.closeHeaderHeight = 48
        this.isMobile = false
      } else {
        this.isMobile = true
      }
    },

    setStickyAreaHeight() {
      this.sticky.style.height = 0
      this.sticky.style.height = `${this.pageHeight}px`
    },

    // all height combined
    setStageHeight() {
      let fullHeight = 0
      this.panels.forEach((elem, index) => {
        fullHeight +=
          parseInt(elem.getAttribute('data-scroll')) + this.openHeaderHeight
      })
      //   fullHeight += fullHeight - (this.isMobile ? 0 : 200)
      this.wrapper.style.height = `${fullHeight}px`
    },

    setPanelHeight() {
      const tabsHeight = this.panels.length * this.closeHeaderHeight
      this.panels.forEach((elem, index) => {
        // this panel height might need ot change...
        const panelHeight =
          this.pageHeight -
          (this.panels.length - 1) * this.closeHeaderHeight +
          this.borderRadius

        elem.style.height = `0px`
        elem.style.height = `${panelHeight}px`
        const visibleHeight = panelHeight - this.borderRadius
        elem.setAttribute('data-visible-height', visibleHeight)
        if (index > 0) {
          const startPointTop =
            this.pageHeight - tabsHeight + this.closeHeaderHeight * index
          elem.style.top = `${startPointTop}px`
        }

        elem.querySelector('.panel-content__text').style.height = `${
          visibleHeight - this.openHeaderHeight
        }px`
      })

      this.setStageHeight()
    },

    setPanelAttributes() {
      this.panels.forEach((elem, index) => {
        const textAreaHeight = elem.querySelector(
          '.panel-content__text'
        ).offsetHeight
        const scrollableHeight = elem.querySelector('.scroller').offsetHeight

        if (scrollableHeight > textAreaHeight) {
          elem.setAttribute('data-overflow', scrollableHeight - textAreaHeight)
        } else {
          elem.setAttribute('data-overflow', 0)
        }
      })

      this.panels.forEach((elem, index) => {
        let h =
          parseInt(elem.getAttribute('data-visible-height')) -
          this.openHeaderHeight +
          parseInt(elem.getAttribute('data-overflow')) -
          (this.openHeaderHeight - this.closeHeaderHeight) * index
        elem.setAttribute('data-scroll', h)
      })

      this.panels.forEach((elem, index) => {
        let newStart = 0
        this.panels.forEach((nestedElem, nestedIndex) => {
          if (index > nestedIndex) {
            newStart += parseInt(nestedElem.getAttribute('data-scroll'))
          }
        })

        elem.setAttribute('data-start', newStart)
      })
      this.panels.forEach((elem, index) => {
        const nextElem = this.panels[index + 1]
        if (nextElem) {
          const nextStart = parseInt(nextElem.getAttribute('data-start'))

          elem.setAttribute('data-end', nextStart)
        } else {
          // the last elem needs a little tlc
          elem.setAttribute(
            'data-end',
            parseInt(elem.getAttribute('data-start')) +
              parseInt(elem.getAttribute('data-visible-height')) +
              parseInt(elem.getAttribute('data-overflow'))
          )
        }
      })
    },

    transformPanels() {
      this.panels.forEach((panel, index) => {
        const activeScrollStart = parseInt(panel.getAttribute('data-start'))
        const activeScrollEnd = parseInt(panel.getAttribute('data-end'))

        this.transFormPanel(panel, index, activeScrollStart, activeScrollEnd)
      })
    },
    transFormPanel(panel, index, start, end) {
      let active = false
      if (this.scrollPos > start && this.scrollPos < end) {
        active = true
        panel.classList.add('is-active')
      } else {
        active = false
        panel.classList.remove('is-active')
      }

      const relativePanelScroll = this.scrollPos - start

      if (active) {
        const innerOverflow = parseInt(panel.getAttribute('data-overflow'))
        if (innerOverflow <= relativePanelScroll) {
          const nextPanel = this.panels[index + 1]
          const normalizedScroll = (relativePanelScroll - innerOverflow) * -1
          if (nextPanel) {
            nextPanel.style.transform = `translateY(${normalizedScroll}px)`
          }
        } else {
          const tInner = relativePanelScroll * -1
          const scroller = panel.querySelector('.scroller')
          scroller.style.transform = `translateY(${tInner}px)`
        }
      }

      if (relativePanelScroll > 0) {
        if (index === 0) {
          return
        } else {
          const updatePanel = this.panels[index - 1]
          const fixedScroll = parseInt(updatePanel.getAttribute('data-scroll'))
          panel.style.transform = `translateY(-${
            fixedScroll - parseInt(updatePanel.getAttribute('data-overflow'))
          }px)`
        }
      }

      const startValue = parseInt(panel.getAttribute('data-start'))

      if (
        relativePanelScroll <
        (panel.offsetHeight - this.borderRadius - this.closeHeaderHeight) * -1
      ) {
        panel.style.transform = `translateY(${0}px)`
      }
    },

    ready() {
      this.updateValues()
      this.setStickyAreaHeight()
      this.setPanelHeight()
      this.setPanelAttributes()
      this.setScrollPos()
      this.transformPanels()
    },
  },
  {
    init() {
      this.navHeight = 88
      this.wrapper = document.querySelector('.panel-wrapper')
      this.sticky = document.querySelector('.sticky-panel-wrapper')
      this.panels = [...this.wrapper.querySelectorAll('.panel')]
      this.openHeaderHeight = 68
      this.closeHeaderHeight = 68
      this.borderRadius = 40
      this.winScrollPos = window.scrollY
      this.pageHeight = 0
      this.scrollPos = 0
      this.isMobile = false

      document.addEventListener('scroll', this.ready)
      window.addEventListener('resize', this.ready)
    },
    destroy() {
      document.removeEventListener('scroll', this.ready)
      window.removeEventListener('resize', this.ready)
    },
  }
)

export default services
