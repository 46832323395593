import { createBehavior } from '@area17/a17-behaviors'
import * as focusTrap from 'focus-trap'

const chipSelector = createBehavior(
  'chipSelector',
  {
    open() {
      this.$node.classList.add('is-open')
      this.trap = focusTrap.createFocusTrap(this.$node, {
        onDeactivate: () => {
          this.close()
        },
      })
      this.trap.activate()
    },
    close() {
      this.$node.classList.remove('is-open')
      this.trap.deactivate()
    },
  },
  {
    init() {
      this.isOpen = false
      this.$node.addEventListener('click', () => {
        if (this.isOpen) {
          this.close()
        } else {
          this.open()
        }
        this.isOpen = !this.isOpen
      })
    },
  }
)

export default chipSelector
