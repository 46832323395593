import { createBehavior } from '@area17/a17-behaviors'
import { Swiper, EffectFade, Navigation } from 'swiper'
import formatData from '../utils/formatData'

import 'swiper/scss'
import 'swiper/scss/effect-fade'

Swiper.use([Navigation, EffectFade])
window.dataLayer = window.dataLayer || [];
let lastIndex = 0;
let index = 0;
let names = [];
let carousel;

const test = createBehavior(
  'carousel',
  {
    setActive(currentIndex) {
      this.navItems.forEach((elem, index) => {
        elem.classList.remove('is-active')
        if (index === currentIndex) {
          elem.classList.add('is-active')
        }
      })
    },

    setNavJump() {
      this.navItems.forEach((elem, index) => {
        elem.addEventListener('click', () => {
          this.carousel.slideTo(index)
          this.carousel2.slideTo(index)
        })
      })
    },

    trackSwipeLeft(){
      window.dataLayer.push({
          'event': 'visitor interaction',
          'interaction_name': 'carousel swipe',
          'swipe_from': formatData(names[lastIndex]),
          'swipe_to': formatData(names[index]),
          'swipe_direction': 'left'
      });
    },

    trackSwipeRight(){
      window.dataLayer.push({
          'event': 'visitor interaction',
          'interaction_name': 'carousel swipe',
          'swipe_from': formatData(names[lastIndex]),
          'swipe_to': formatData(names[index]),
          'swipe_direction': 'right'
      });
    },

    trackLinkClick(){
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'carousel to full article click',
        'current_selection': formatData(names[index]),
        });
    },
    

    trackPeopleClick(){
      window.dataLayer.push({
        'event': 'visitor interaction',
        'interaction_name': 'carousel people click',
        'click_from': formatData(names[lastIndex]),
        'click_to': formatData((names[index])),
        });
    }

  },
  {
    init() {
      const slider1 = this.getChild('mainslider')
      const slider2 = this.getChild('secondaryslider')
      this.navItems = [
        ...this.$node.querySelectorAll('.quote-slider-1__nav button'),
      ]

      this.carousel = new Swiper(slider1, {
        effect: 'fade',
        allowTouchMove: false,
        simulateTouch: false,
        fadeEffect: { crossFade: true },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
      })
      carousel = this.carousel;

      this.carousel2 = new Swiper(slider2, {
        effect: 'fade',
        simulateTouch: false,
        allowTouchMove: false,
        fadeEffect: { crossFade: true },
      })

      this.carousel.on('slideChange', (swiper) => {
        lastIndex = index
        index = swiper.activeIndex
        this.setActive(index)
        this.carousel2.slideTo(index)
      })


      this.setNavJump();

      //list of names
      this.$node.querySelectorAll(".quote-slide__byline").forEach((elem)=>{
        names.push(elem.getAttribute('data-name'));
      })

      //data layer tracking
      this.$node.querySelector("[data-button-prev]").addEventListener('click', this.trackSwipeLeft);
      this.$node.querySelector("[data-button-next]").addEventListener('click', this.trackSwipeRight);
      this.$node.querySelectorAll("[data-slide-link]").forEach(item => {
        item.addEventListener('click', this.trackLinkClick)
      });
      this.$node.querySelectorAll("[data-people-button]").forEach(item => {
        item.addEventListener('click', this.trackPeopleClick)
      });

    },
  }
)

export default test
