import { createBehavior } from '@area17/a17-behaviors'
import { slideDown, slideUp, slideStop } from 'slide-anim'

const SectionExpander = createBehavior(
  'SectionExpander',
  {
    toggle(e) {
      e.preventDefault()
      slideStop(this.$content)
      this.isOpen = !this.isOpen

      e.currentTarget.querySelector('span').innerText = this.isOpen
        ? this.showLessText
        : this.showMoreText

      if (this.isOpen) {
        this.open()
      } else {
        this.close()
      }
    },

    open() {
      this.$trigger.setAttribute('aria-expanded', 'true')
      slideDown(this.$content, { duration: 500 }).then(() => {
        this.$content.querySelector('a').focus()
      })
    },

    close() {
      slideUp(this.$content, { duration: 500 })
      this.$trigger.setAttribute('aria-expanded', 'false')
    },
  },
  {
    init() {
      this.isOpen = false
      this.$trigger = this.getChild('trigger')
      this.$content = this.getChild('content')
      this.showMoreText = 'Show more'
      this.showLessText = 'Show less'

      if (this.$trigger !== null) {
        this.$trigger.addEventListener('click', this.toggle, false)
      }
    },
  }
)

export default SectionExpander
