import { createBehavior } from "@area17/a17-behaviors";

const reportNav = createBehavior(
  "reportNav",
  {
    reportNavReveal() {
      const navBtn = document.querySelector(".report__nav__btn");
      if (document.body.contains(navBtn)) {
        const navPanel = document.querySelector(".report__nav__panel");
        const navBtnOpen = document.querySelector(".report__nav__open");
        const navBtnClose = document.querySelector(".report__nav__close");
        const body = document.querySelector("body");
        const navLinks = document.querySelectorAll(".report__nav__link");
        const bgOverlay = document.querySelector(".bg-blur--overlay");
        navBtn.addEventListener("click", function () {
          navPanel.classList.toggle("report__nav--show");
          navBtnOpen.classList.toggle("report__nav__icon--hide");
          navBtnClose.classList.toggle("report__nav__icon--show");
          body.classList.toggle("report--overflow");
          bgOverlay.classList.toggle("report__overlay--show");
        });
        navLinks.forEach((n) => {
          n.addEventListener("click", (event) => {
            navPanel.classList.remove("report__nav--show");
            navBtnOpen.classList.remove("report__nav__icon--hide");
            navBtnClose.classList.remove("report__nav__icon--show");
            body.classList.remove("report--overflow");
            bgOverlay.classList.remove("report__overlay--show");
          });
        });
        document.addEventListener("click", function (event) {
          if (event.target.closest(".report__nav")) return;
          navPanel.classList.remove("report__nav--show");
          navBtnOpen.classList.remove("report__nav__icon--hide");
          navBtnClose.classList.remove("report__nav__icon--show");
          bgOverlay.classList.remove("report__overlay--show");
          body.classList.remove("report--overflow");
          body.style.overflow = "visible";
        });
      }
    },
    reportAnchorLinks() {
      const reportAnchors = document.getElementById("reportAnchors");
      if (document.body.contains(reportAnchors)) {
        const fixedNav = reportAnchors;
        const fixedNavBLock = document.getElementById("reportAnchorsBlock");
        var stuck = false;
        function getDistance() {
          const topDist = fixedNavBLock.offsetTop;
          return topDist;
        }
        window.onscroll = () => {
          const distance = getDistance() - window.pageYOffset;
          const offset = window.pageYOffset;
          if (distance < 0 && !stuck) {
            fixedNav.classList.add("report__anchors__scroll");
            stuck = true;
          } else if (stuck && distance > 0) {
            fixedNav.classList.remove("report__anchors__scroll");
            stuck = false;
          }
          const sections = document.querySelectorAll(".report__block");
          const navLi = document.querySelectorAll(
            ".report__anchors__block ul li"
          );
          var current = "";
          sections.forEach((section) => {
            const sectionTop = section.offsetTop;
            if (offset >= sectionTop - 230) {
              current = section.getAttribute("id");
            }
          });

          navLi.forEach((li) => {
            li.classList.remove("active");
            if (li.classList.contains(current)) {
              li.classList.add("active");
            }
          });
        };
      }
    },
    reportAnchorScroll() {
      const anchorLinks = document.querySelectorAll(".report-scroll");
      if (anchorLinks) {
        for (let i = 0; i < anchorLinks.length; i++) {
          anchorLinks[i].addEventListener("click", anchorLinkClick);
        }

        function anchorLinkClick(event) {
          smoothScroll(event);
        }
        function smoothScroll(event) {
          event.preventDefault();
          const targetId =
            event.currentTarget.getAttribute("href") === "#"
              ? "header"
              : event.currentTarget.getAttribute("href");
          const targetPosition =
            document.querySelector(targetId).offsetTop - 220;
          const startPosition = window.pageYOffset;
          const distance = targetPosition - startPosition;
          const duration = 1000;
          let start = null;

          window.requestAnimationFrame(step);

          function step(timestamp) {
            if (!start) start = timestamp;
            const progress = timestamp - start;
            window.scrollTo(
              0,
              easeInOutCubic(progress, startPosition, distance, duration)
            );
            if (progress < duration) window.requestAnimationFrame(step);
          }
        }

        function linear(t, b, c, d) {
          return (c * t) / d + b;
        }

        function easeInOutQuad(t, b, c, d) {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t + b;
          t--;
          return (-c / 2) * (t * (t - 2) - 1) + b;
        }

        function easeInOutCubic(t, b, c, d) {
          t /= d / 2;
          if (t < 1) return (c / 2) * t * t * t + b;
          t -= 2;
          return (c / 2) * (t * t * t + 2) + b;
        }
      }
    },
  },
  {
    init() {
      this.reportNavReveal();
      this.reportAnchorLinks();
      this.reportAnchorScroll();
    },
  }
);

export default reportNav;
