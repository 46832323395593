import { createBehavior } from '@area17/a17-behaviors'

const pagination = createBehavior(
  'pagination',
  {
    resize(width) {
      this.$node.style.setProperty('--dynamic-size', `${width + 13}px`)
    },
  },
  {
    init() {
      const resizingSelect = this.$node.querySelector('select')
      const helperElement = this.$node.querySelector('.helper-element')

      if (!resizingSelect) return

      const text = resizingSelect.querySelector('option:checked').innerText
      helperElement.innerHTML = text

      const initResize = (event) => {
        helperElement.innerHTML =
          event.target.querySelector('option:checked').innerText
        this.resize(helperElement.offsetWidth)
      }

      resizingSelect.addEventListener('change', initResize)

      window.addEventListener('resize', () => {
        const text = resizingSelect.querySelector('option:checked').innerText
        helperElement.innerHTML = text
        this.resize(helperElement.offsetWidth)
      })

      if (this.$node.getAttribute('data-link')) {
        this.$node.querySelector('select').addEventListener('change', (e) => {
          const value = this.$node.querySelector('select').value
          window.location = `${value}`
        })
      }

      setTimeout(() => {
        this.resize(helperElement.offsetWidth)
      })
    },
  }
)

export default pagination
