import { createBehavior } from "@area17/a17-behaviors";
import formatData from '../utils/formatData'

window.dataLayer = window.dataLayer || [];

const author = createBehavior(
  "author",
  {
    trackAuthorLinkedInClick(name) {
      window.dataLayer.push({
        event: "visitor interaction",
        interaction_name: "author linkedin click",
        author_linkedin_name: formatData(name),
      });
    },
  },
  {
    init() {
      //DATA LAYER
      //primary items
      let item = this.$node.querySelector("[data-author-connect]")
      item.addEventListener("click", (e) => {
        this.trackAuthorLinkedInClick(item.getAttribute("data-authorName"))
    });
    },
  }
);

export default author;
